import styled from "styled-components";
import { Swiper } from "swiper/react";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { SliderPaginationOptions } from "@ecp-boxes/boxes/BoxSlider/BoxSlider.types";

export const StyledSwiper = styled(Swiper)<{
  $settings: {
    elementsCount: number;
    spacingHorizontal?: number;
    spacingVertical?: number;
    theme: IThemeState;
    paginationStyle?: SliderPaginationOptions;
    centerSlides?: boolean;
  };
}>(
  ({ $settings }) => `
position: relative;
display: flex;

${
  $settings.paginationStyle === SliderPaginationOptions.ARROWS &&
  $settings.spacingHorizontal
    ? `
  width: calc(100% - ${$settings.spacingHorizontal}px);
  margin: 0 ${$settings.spacingHorizontal}px;`
    : `width: 100%;`
}


.swiper-container {
  height: 100%;
  width: 100% !important;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  ${$settings.centerSlides && " display: flex; align-items: center;"}
  ${
    $settings.paginationStyle === SliderPaginationOptions.DOTS ||
    $settings.paginationStyle === SliderPaginationOptions.NUMBERS
      ? "margin-bottom: 36px"
      : ""
  } ;
}

.swiper-slide {
  width: ${100 / $settings.elementsCount}%;
  word-break: break-all;
  height: initial;
  margin: ${$settings.spacingVertical ?? 0}px 0;
  .article-list__slider__single-slide__content{
    padding: 0 ${$settings.spacingHorizontal ?? 0}px 0 0;
    width: inherit;
  }
}

.swiper-button-prev, .swiper-button-next {
  color: #81889c !important;
  height: 40px;
  width: 40px;
  z-index: var(--zindex-swiper-buttons);
  transition: all var(--ecp-transition);
}



.swiper-button-prev:hover, .swiper-button-next:hover {
  background-color: #f0f5fe;
}

.swiper-button-prev:active, .swiper-button-next:active {
  background-color: #dce9fd;
}

.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 23px;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: auto !important;
}

.swiper-pagination{
  min-width: 100px !important;
}

.swiper-pagination-bullet:not(.pagination-counter) {
  height: 12px;
  width: 12px;
  background-color: #ffffff;
  opacity: 1;
  transition: all var(--ecp-transition);
}

.swiper-pagination-bullet-active {
  background-color: #81889c !important;
}

.pagination-counter, .pagination-counter.swiper-pagination-bullet-active {
  width: 20px;
  height: auto;
  border-radius: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 2px;
  font-size: 16px;
  font-weight: 600;
  user-select: none;
}

.pagination-counter {
  color: #81889c;
  cursor: pointer;
  opacity: 1;
  transition: all var(--ecp-transition);
}

.pagination-counter:not(.swiper-pagination-bullet-active):hover {
  background-color: #f0f5fe;
}

.pagination-counter:not(.swiper-pagination-bullet-active):active {
  background-color: #dce9fd;
}

.pagination-counter.swiper-pagination-bullet-active {
  color: #293740;
  cursor: auto;
}
`
);
