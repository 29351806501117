import { useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { isPortalSide } from "../../../helpers/helpers";
import ProductModule from "../../../shared/components/domain/Product/ProductModule/ProductModule";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { getElementsForPage } from "../BoxProductSlider.methods";
import {
  IBoxProductSliderContent,
  IBoxProductSliderContentSettings,
  IBoxProductSliderDisplaySettings,
  IBoxProductSliderProps,
  ProductSliderBoxType,
  ProductSliderBoxViewType,
} from "../BoxProductSlider.types";
import useBoxProductSliderData from "../hooks/useBoxProductSliderData";
import { StyledProductSliderGrid } from "./BoxProductSliderGrid.styled";
import PaginationGridProductSlider from "./ProductSliderPaginationGrid/ProductSliderPaginationGrid";
import { PageCategoryType, TRegularBoxType } from "@ecp-pageTypes";
import { useBoxContext } from "../../../structure/Contexts/BoxContext";
import PageInPageComponent from "../../../structure/PageInPage/PageInPageComponent";

export interface IBoxProductSliderGrid {
  contentSettings: IBoxProductSliderContentSettings;
  content: IBoxProductSliderContent;
  displaySettings: IBoxProductSliderDisplaySettings;
  id: number;
  type: TRegularBoxType;
  editMode: boolean;
}

const BoxProductSliderGrid = ({
  contentSettings,
  content,
  displaySettings,
  id,
  type,
  editMode,
}: IBoxProductSliderGrid) => {
  const { t } = useTranslation();
  const { settings } = useBoxContext<IBoxProductSliderProps>();
  const productSliderGridRef = useRef<HTMLDivElement>(null);
  const [pauseInterval, setPauseInterval] = useState(false);
  const { productsData, currentPage, setCurrentPage, productsPerPage } =
    useBoxProductSliderData(contentSettings);

  const isMobile = useIsMobilePortal();

  const productsForPage = getElementsForPage(
    productsData.products,
    productsPerPage,
    currentPage
  );
  useEffect(() => {
    const ref = productSliderGridRef.current;
    ref?.addEventListener("mouseenter", () => setPauseInterval(true));
    ref?.addEventListener("mouseleave", () => setPauseInterval(false));
    return () => {
      ref?.removeEventListener("mouseenter", () => setPauseInterval(true));
      ref?.addEventListener("mouseleave", () => setPauseInterval(false));
    };
  }, [productSliderGridRef]);

  const numberOfPages = Math.ceil(
    productsData.products.length / productsPerPage
  );

  const numberOfProductsPerRow = isMobile
    ? Math.min(contentSettings.sliderSettings.numberOfProductsPerRow, 2)
    : contentSettings.sliderSettings.numberOfProductsPerRow;

  if (productsData.products?.length === 0)
    return (
      <div className="product-slider__empty-box">
        {!isPortalSide() && t("global_keys.empty_box")}
      </div>
    );
  return (
    <>
      <StyledProductSliderGrid
        data-testid="product-slider-grid"
        numberOfProductsPerRow={numberOfProductsPerRow}
        displaySettings={displaySettings}
        className="product-slider-grid"
        ref={productSliderGridRef}
      >
        {productsForPage.map((product) =>
          settings.page_product_card === "" ? (
            <ProductModule
              data-testid="product"
              product={product}
              moduleSettings={displaySettings.module}
              productPhoto={contentSettings.productPhoto}
              badge={contentSettings.badge}
              favorites={contentSettings.favorites}
              buttonText={content.productButtonText}
              ratingText={content.ratingText}
              productSettings={contentSettings.product}
              orderInModule={displaySettings.orderInModule}
              key={product.sku}
              boxId={id}
              boxType={type}
              variantSettings={contentSettings.variants?.find(
                (variant) => variant.code === product.axis
              )}
              attributesLineDetection={productsData.attributesLineDetection}
              leftPictureMode={
                contentSettings.sliderSettings.type ===
                  ProductSliderBoxType.GRID &&
                contentSettings.sliderSettings.viewType ===
                  ProductSliderBoxViewType.LEFT_PICTURE
                  ? {
                      photoSize: contentSettings.productPhoto.photoSize,
                      pictureAlignment: displaySettings.module.pictureAlignment,
                      attributesAlignment:
                        displaySettings.module.attributesAlignment,
                      spaceBetweenPhotoAndAttributes:
                        displaySettings.module.spaceBetweenPhotoAndAttributes,
                      badgesShown: contentSettings.badge.show,
                    }
                  : undefined
              }
            />
          ) : (
            <PageInPageComponent
              key={product.sku}
              pageContext={{
                productSku: product.sku,
                type: PageCategoryType.PRODUCT,
              }}
              url={settings.page_product_card}
            />
          )
        )}
      </StyledProductSliderGrid>
      {numberOfPages > 1 && (
        <div className="product-slider-grid__pagination">
          <PaginationGridProductSlider
            pauseInterval={pauseInterval}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={numberOfPages}
            paginationStyle={displaySettings.paginationStyleGrid}
            rotatePages={displaySettings.slidesRotate}
            editMode={editMode}
          />
        </div>
      )}
    </>
  );
};
export default BoxProductSliderGrid;
